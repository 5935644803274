<template>
  <div class="inteBox">
    <intNavheader activeNameCheck="third"></intNavheader>
    <div class="titleTopLine"></div>

    <div class="titleTopCenter noflex-l-s">
      <div class="titleTopCenterLeft">
        <!-- <div :class="userInfo.isAuditDiagnose == 'True' ? 'titleTopCenterLeftBox' : 'titleTopCenterLeftBoxno'" @click="goDoctor">处方审核</div> -->
        <waitNumber :waitDataTotalNumber='waitDataTotal'></waitNumber>

        <div class="titleTopCenterLeftBox" @click="gonewDoctor">新增方剂处方</div>
        <div class="titleTopCenterLeftBox" @click="gonmedDoctor">新增成药处方</div>
        <div class="titleTopCenterLeftBox" @click="gotraDoctor">新增输液处方</div>
      </div>
      <div class="titleTopCenterCenter">
        <div class="titleTopCenterCenterTop" v-if="addtoNew == 0">
          <div class="Content1Title">处方列表</div>
          <div class="flex-l" style="margin-top: 20px">
            <el-form :model="searchForm" :inline="true">
              <el-form-item label="处方类型">
                <el-select v-model="searchForm.type" style="width: 100%" placeholder="请选择" size="small">
                  <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="">
                <el-input v-model="searchForm.keyword" type="text" size="small" placeholder="处方名称" @keyup.enter.native="Search" clearable></el-input>
              </el-form-item>
              <el-form-item>
                <el-button type="primary" size="small" @click="Search()">搜索</el-button>
              </el-form-item>
            </el-form>
          </div>
          <el-table :data="MyCommonPrescriptionList" stripe style="width: 100%; margin-top: 30px" height="calc(100vh - 300px)" :header-cell-style="{ background: '#F5F7FC' }">
            <el-table-column prop="prescriptionName" label="处方名称" width="180">
              <template slot-scope="scope">
                <div class="titleTopLeftTableC">{{ scope.row.prescriptionName }}</div>
              </template>
            </el-table-column>
            <el-table-column prop="type" label="类型" align="center">
              <template slot-scope="scope">
                <div class="" v-if="scope.row.type == 0">方剂处方</div>
                <div class="" v-if="scope.row.type == 1">成药处方</div>
                <div class="" v-if="scope.row.type == 2">输液处方</div>
              </template>
            </el-table-column>
            <el-table-column prop="addTime" label="上传时间" align="center">
              <template slot-scope="scope">
                <div class="">{{ scope.row.addTime | timefilters }}</div>
              </template>
            </el-table-column>
            <el-table-column label="操作" align="center">
              <template slot-scope="scope">
                <el-button type="text" size="small" @click="goEditClick(scope.row)">编辑</el-button>
                <el-button @click="deleteClick(scope.row)" type="text" size="small">删除</el-button>
              </template>
            </el-table-column>
          </el-table>
          <el-pagination @current-change="changePage" :page-size="pageSize" :current-page="pageIndex" :total="dataTotal" layout="total, prev, pager, next, jumper"></el-pagination>
        </div>
        <div v-if="addtoNew == 1" class="titleTopCenterCenterTop" style="padding-right: 20%; overflow-y: auto; padding-bottom: 20px; height: calc(100% - 30px)">
          <el-page-header @back="goBack" :content="patientFormPre.id ? '编辑方剂处方' : '新增方剂处方'"> </el-page-header>
          <!-- <div class="Content1Title"><i class="el-icon-back" @click="goBack"></i> {{ patientFormPre.id ? "编辑方剂处方" : "新增方剂处方" }}</div> -->
          <el-form :model="patientFormPre" ref="patientFormPre" label-position="top">
            <div class="table3Box">
              <el-form-item prop="prescriptionName" label="中药方剂名称" :rules="[{ required: true, message: '请输入中药方剂名称', trigger: 'blur' }]">
                <div class="flex-l-b titleSize14" style="margin-bottom: 20px">
                  <el-input v-model="patientFormPre.prescriptionName" placeholder="请输入中药方剂名称" style="width: 40%"></el-input>
                  <div @click="goAdd" style="color: #01c2ac">载入经典方</div>
                </div>
              </el-form-item>
              <el-table :data="patientFormPre.mzPrescriptionDetail" border>
                <el-table-column type="index" width="50"> </el-table-column>
                <el-table-column prop="drugOrgId" align="center" label="成分">
                  <template slot-scope="scope">
                    <el-form-item :prop="'mzPrescriptionDetail.' + scope.$index + '.drugOrgId'" :rules="[{ required: true, message: '请选择成分', trigger: 'blur' }]">
                      <el-select v-model="scope.row.drugOrgName" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                        <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="weight" align="center" label="用量">
                        <template slot-scope="scope">
                          <el-form-item :prop=" 'mzPrescriptionDetail.' + scope.$index + '.weight'" :rules="[{ required: true, message: '请选择用量', trigger: 'blur' }]">
                            <div style="position: relative">
                              <el-input v-model="scope.row.weight" size="small" placeholder="用量(g)">
                              </el-input>
                              <!-- <el-tooltip effect="dark" content="库存不足" placement="bottom" v-if="scope.row.inventoryNumber == '0' || scope.row.drugInventoryNumber == '0' || scope.row.weight > scope.row.inventoryNumber">
                                <el-button style="color: #fc1414; font-size: 14px" icon="el-icon-info" class="infoWarn1" type="text"></el-button>
                              </el-tooltip> -->
                            </div>
                          </el-form-item>
                        </template>
                      </el-table-column>
                      <el-table-column prop="recommendDosage" align="center" label="推荐用量" width="120">
                        <template slot-scope="scope">
                            <div style="position: relative">
                              {{ scope.row.recommendDosage ? scope.row.recommendDosage : '--'}}
                            </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="remark" align="center" label="炮制方法" width="120">
                        <template slot-scope="scope">
                          <div style="position: relative">
                              {{ scope.row.remark ? scope.row.remark : '--'}}
                            </div>
                        </template>
                      </el-table-column>
                      <el-table-column prop="decoct" align="center" label="煎药方法">
                        <template slot-scope="scope">
                          <el-form-item>
                            <el-select v-model="scope.row.decoct" size="mini" placeholder="煎药方法">
                              <el-option v-for="item in usageOption" :key="item" :label="item" :value="item"> </el-option>
                            </el-select>
                          </el-form-item>
                        </template>
                      </el-table-column>
                <!-- <el-table-column prop="remark" align="center" label="备注">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.remark" size="small" placeholder="输入备注信息"> </el-input>
                  </template>
                </el-table-column> -->
                <el-table-column width="100" align="center">
                  <template slot-scope="scope">
                    <div class="flex-l">
                      <!-- <div>￥{{ scope.row.tradingPrice ? scope.row.weight * 1 * scope.row.tradingPrice : "0.00" }}</div> -->
                      <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="gondeletePre(scope.$index)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="titleSize14 titleTopright1 titleTopright1Line" @click="gonaddPre">添加中药材</div>
              <div class="flex-l" style="margin-top: 5px">
                <el-form-item prop="name" label="剂数"> <el-input-number class="stepInput" v-model="patientFormPre.dosage" controls-position="right" :min="1"></el-input-number>剂 </el-form-item>

                <el-form-item prop="age" label="每日剂量">
                  <div class="flex-l"><el-input-number class="stepInput" v-model="patientFormPre.dosageNumber" controls-position="right" :min="1"></el-input-number>剂</div>
                </el-form-item>
                <el-form-item prop="frequency" label="用药频率" :rules="[{ required: true, message: '请选择用药频率', trigger: 'blur' }]">
                  <div class="flex-l">
                    <el-select v-model="patientFormPre.frequency" placeholder="用药频率">
                      <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                  </div>
                </el-form-item>
              </div>
              <el-form-item prop="usage" label="用法" :rules="[{ required: true, message: '请选择用法', trigger: 'blur' }]">
                <div class="flex-l">
                  <el-select v-model="patientFormPre.usage" placeholder="用法">
                    <el-option v-for="item in usage" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item prop="request" label="服用要求" style="margin-top: 20px">
                <div class="flex-l">
                  <el-select v-model="patientFormPre.request" placeholder="服用要求">
                    <el-option v-for="item in requestOption" :key="item" :label="item" :value="item"> </el-option>
                  </el-select>
                </div>
              </el-form-item>
              <el-form-item style="text-align: right; margin-top: 20px">
                <!-- <el-button  @click="patientFormPreResetForm('patientFormPre')">重置</el-button> -->
                <el-button type="primary" v-if="patientFormPre.id" @click="editpatientFormPreForm('patientFormPre')">确认修改</el-button>
                <el-button type="primary" v-else @click="addpatientFormPreForm('patientFormPre')">存为常用处方</el-button>
              </el-form-item>
            </div>
          </el-form>
        </div>
        <div v-if="addtoNew == 2" class="titleTopCenterCenterTop" style="padding-right: 20%; overflow-y: auto; padding-bottom: 20px; height: calc(100% - 30px)">
          <el-page-header @back="goBack" :content="patientFormPreCY.id ? '编辑成药处方' : '新增成药处方'"> </el-page-header>
          <!-- <div class="Content1Title"><i class="el-icon-back" @click="goBack"></i> {{ patientFormPreCY.id ? "编辑成药处方" : "新增成药处方" }}</div> -->
          <div class="table3Box">
            <el-form :model="patientFormPreCY" ref="patientFormPreCY" label-position="top">
              <el-form-item prop="prescriptionName" label="成药方剂名称" :rules="[{ required: true, message: '请输入成药方剂名称', trigger: 'blur' }]">
                <div class="flex-l-b titleSize14" style="margin-bottom: 20px">
                  <el-input v-model="patientFormPreCY.prescriptionName" placeholder="请输入成药方剂名称" style="width: 40%"></el-input>
                </div>
              </el-form-item>
              <el-table :data="patientFormPreCY.mzPrescriptionDetail" border>
                <el-table-column prop="drugOrgId" align="center" label="药品名称">
                  <template slot-scope="scope">
                    <el-form-item :prop="'mzPrescriptionDetail.' + scope.$index + '.drugOrgName'" :rules="[{ required: true, message: '请选择成分', trigger: 'blur' }]">
                      <el-select v-model="scope.row.drugOrgId" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                        <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column prop="groupNumber" align="center" label="组号" width="120">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.groupNumber" size="small" placeholder="输入组号"> </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="usage" align="center" label="用法" width="130">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.usage" size="mini" placeholder="用法">
                      <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="frequency" align="center" label="用药频率" width="150">
                  <template slot-scope="scope">
                    <el-select v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                      <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                      <el-select style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit" size="mini" placeholder="单位">
                        <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="defaultDosage" align="center" label="开药量" width="160">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.defaultDosageList.defaultDosage" size="small" placeholder="开药量">
                      <el-select style="width: 75px" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size="mini" placeholder="单位">
                        <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </el-input>
                  </template>
                </el-table-column>
                <el-table-column width="100" align="center">
                  <template slot-scope="scope">
                    <div class="flex-l">
                      <!-- <div>￥{{ scope.row.tradingPrice ? scope.row.defaultDosageList.defaultDosage * 1 * scope.row.tradingPrice : "0.00" }}</div> -->
                      <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="gondeletePreCY(scope.$index)"></i>
                    </div>
                  </template>
                </el-table-column>
              </el-table>
              <div class="titleSize14 titleTopright1" @click="gonaddPreCY()">添加药品</div>
              <el-form-item style="text-align: right; margin-top: 20px">
                <el-button v-if="patientFormPreCY.id" type="primary" @click="editCyFormPreForm('patientFormPreCY')">确认修改</el-button>
                <el-button v-else type="primary" @click="addCyFormPreForm('patientFormPreCY')">存为常用处方</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
        <div v-if="addtoNew == 3" class="titleTopCenterCenterTop" style="padding-right: 20%; overflow-y: auto; padding-bottom: 20px; height: calc(100% - 30px)">
          <el-page-header @back="goBack" content="新增输液处方"> </el-page-header>
          <!-- <div class="Content1Title"><i class="el-icon-back" @click="goBack"></i> 新增输液处方</div> -->
          <div class="table3Box">
            <el-form :model="patientFormPreSY" ref="patientFormPreSY" label-position="top">
              <el-form-item prop="prescriptionName" label="输液处方名称" :rules="[{ required: true, message: '请输入输液处方名称', trigger: 'blur' }]">
                <div class="flex-l-b titleSize14">
                  <el-input v-model="patientFormPreSY.prescriptionName" placeholder="请输入输液处方名称" style="width: 40%"></el-input>
                </div>
              </el-form-item>

              <div v-for="(itemComSY1, indexComSY1) in patientFormPreSY.zucompositionList" :key="indexComSY1" style="margin-top: 20px">
                <div class="flex-l titleSize14">
                  <div class="">组{{ indexComSY1 * 1 + 1 }}</div>
                  <div class="flex-l titleTopright1 titleSize14">
                    <div style="margin-left: 15px" @click="godeleteSYzuList(indexComSY1)">删除改组</div>
                  </div>
                </div>
                <el-table :data="itemComSY1.mzPrescriptionTitle" border>
                  <el-table-column prop="usage" align="center" label="用法">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.usage" size="mini" placeholder="用法">
                        <el-option v-for="item in remarkOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="frequency" align="center" label="用药频率">
                    <template slot-scope="scope">
                      <el-select v-model="scope.row.frequency" size="mini" placeholder="用药频率">
                        <el-option v-for="item in frequencyOption" :key="item" :label="item" :value="item"> </el-option>
                      </el-select>
                    </template>
                  </el-table-column>
                  <el-table-column prop="drippingSpeedList" align="center" label="滴速">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.drippingSpeedList.drippingSpeed" size="small" placeholder="单次剂量">
                        <el-select style="width: 75px" slot="append" v-model="scope.row.drippingSpeedList.drippingSpeedUnit" size="mini" placeholder="单位">
                          <el-option v-for="item in SpeeOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="startTime" align="center" label="起始日期">
                    <template slot-scope="scope">
                      <el-date-picker v-model="scope.row.startTime" type="date" placeholder="选择日期">
                        <!-- format="yyyy / MM / dd " -->
                      </el-date-picker>
                    </template>
                  </el-table-column>
                  <el-table-column prop="days" align="center" label="连续天数">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.days" size="small" placeholder="天数"> </el-input>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table :data="itemComSY1.mzPrescriptionDetail" border style="margin-top: 10px">
                  <el-table-column prop="compositionName" align="center" label="药品名称">
                    <template slot-scope="scope">
                      <!-- <el-form-item
                                :prop="'patientFormPreSY.'+scope.$index  +'.drugOrgId'"
                              :rules="[{ required: true, message: '请选择成分', trigger: 'blur' }]"
                                  
                          >    -->
                      <el-select v-model="scope.row.drugOrgId" filterable remote size="small" reserve-keyword placeholder="请输入成分" ref="selectLabel" :remote-method="SelectComposition" @change="ConstituteChange($event, scope.row)">
                        <el-option v-for="item in EditDrugCompositio" :key="item.drugOrgId" :label="item.drugOrgName" :value="item.drugOrgId"> </el-option>
                      </el-select>
                      <!-- </el-form-item> -->
                    </template>
                  </el-table-column>
                  <el-table-column prop="st" align="center" label="皮试">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.st" size="small" placeholder="皮试"> </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dosageList" align="center" label="单次剂量" width="160">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.dosageList.dosage" size="small" placeholder="单次剂量">
                        <el-select style="width: 75px" slot="append" v-model="scope.row.dosageList.dosageUnit" size="mini" placeholder="单位">
                          <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column prop="defaultDosageList" align="center" label="开药量" width="160">
                    <template slot-scope="scope">
                      <el-input v-model="scope.row.defaultDosageList.defaultDosage" size="small" placeholder="开药量">
                        <el-select style="width: 75px" slot="append" v-model="scope.row.defaultDosageList.defaultDosageUnit" size="mini" placeholder="单位">
                          <el-option v-for="item in kgOption" :key="item" :label="item" :value="item"> </el-option>
                        </el-select>
                      </el-input>
                    </template>
                  </el-table-column>
                  <el-table-column width="100" align="center">
                    <template slot-scope="scope">
                      <div class="flex-l">
                        <!-- <div>￥{{ scope.row.tradingPrice ? scope.row.defaultDosageList.defaultDosage * 1 * scope.row.tradingPrice : "0.00" }}</div> -->
                        <i class="el-icon-delete" style="color: #01c2ac; margin-left: 10px; font-size: 22px" @click="godeletSYzuDrugList(indexComSY1, scope.$index)"></i>
                      </div>
                    </template>
                  </el-table-column>
                </el-table>
                <div class="flex-l titleSize14">
                  <div class="titleSize14 titleTopright1" @click="goaddSYzuDrugList(indexComSY1)">添加药品</div>
                  <div class="titleSize14 titleTopright1" @click="goaddSYzuList()" style="margin-left: 15px">添加组</div>
                </div>
              </div>

              <!-- <div class="titleSize14 titleTopright1">添加药品</div> -->
              <el-form-item style="text-align: right; margin-top: 20px">
                <!-- <el-button  @click="submitForm('patientForm')">重置</el-button> -->
                <el-button type="primary" v-if="patientFormPreSY.id" @click="editSYFormPreForm('patientFormPreSY')">确认修改</el-button>
                <el-button type="primary" v-else @click="addSYFormPreForm('patientFormPreSY')">存为常用处方</el-button>
              </el-form-item>
            </el-form>
          </div>
        </div>
      </div>
    </div>
    <el-drawer title="载入经典方" :visible.sync="isdrawer" direction="rtl">
      <div style="width: 90%; margin-left: 5%">
        <el-input placeholder="输入处方名称" prefix-icon="el-icon-search" v-model="HerbalValue" @input="inputChange"></el-input>
        <div class="firstRight3Box">
          <div class="flex-l-b" v-for="(item, index) in HerbalList" :key="index">
            <div style="padding: 10px 0" @click="goherbalDetail(item)"><i class="el-icon-circle-plus-outline titleTopLeftTableC" style="margin-right: 5px"></i>{{ item.herbalPrescriptionName }}</div>
          </div>
        </div>
        <div class="firstRight3BottomBox">
          <div class="firstRight3BottomBoxDiv">暂未选择</div>
          <el-table :data="herbalDetail.compositions" v-loading="loading" style="width: 100%">
            <el-table-column prop="compositionName" label="中药材" width="100">
              <template slot-scope="scope">
                <div data-size="14" v-html="scope.row.compositionName1"></div>
              </template>
            </el-table-column>
            <el-table-column prop="dosage" label="用量" width="100"> </el-table-column>
            <el-table-column prop="recommendDosage" label="推荐用量">
                    <template slot-scope="scope">
                          {{ scope.row.recommendDosage ?  scope.row.recommendDosage : '--'}}
                    </template>
                  </el-table-column>
            <el-table-column prop="remark" label="炮制方法"> </el-table-column>
          </el-table>
        </div>
        <div style="text-align: right; padding-top: 20px; border-top: 1px dashed #e6e6e6; margin-right: 20px">
          <el-button type="primary" @click="addgetLoadPrescription()">导入处方</el-button>
        </div>
      </div>
    </el-drawer>
    <div class="titleTopLine"></div>
  </div>
</template>
<script>
import intNavheader from "../../components/intNavheader";
import waitNumber from "../../components/waitNumber";
import { patientMain } from "../../components/patienTmanagement/patienTmanagement";
import { Drug } from "../../components/Drug/Drug";
export default {
  components: {
    intNavheader,
    waitNumber
  },
  data() {
    var patient = new patientMain(this.TokenClient, this.Services.Authorization);
    var drugpatient = new patientMain(this.TokenClient, this.Services.Drug);
    var drug = new Drug(this.TokenClient, this.Services.Drug);
    return {
      patientDomain: patient,
      drugpatientDomain: drugpatient,
      userInfo: this.$store.state.localData.userInfo,
      DrugDomain: drug,
      searchForm: {
        type: "0",
        keyword: "",
      },
      herbalDetail: {},
      isdrawer: false,
      loading: false,
      HerbalList: [],
      jdcompositionList: [],
      HerbalValue: "",
      addtoNew: 0,
      inputValue: "",
      inputValue2: "",
      patientFormPre: {
        mzPrescriptionDetail: [],
        usage: "",
        remark: "",
        dosage: "",
        dosageNumber: "",
        frequency: "",
        prescriptionName: "",
        request: "",
        type: 0,
        prescriptionType: 1,
      },
      patientFormPreCY: {
        mzPrescriptionDetail: [],
        prescriptionName: "",
        type: 1,
        prescriptionType: 1,
      },
      patientFormPreSY: {
        prescriptionName: "",
        type: 2,
        prescriptionType: 1,
        zucompositionList: [
          {
            prescriptionName: "组",
            mzPrescriptionTitle: [
              {
                usage: "",
                frequency: "",
                drippingSpeedList: {
                  drippingSpeed: "",
                  drippingSpeedUnit: "",
                },

                startTime: "",
                days: "",
              },
            ],
            mzPrescriptionDetail: [],
          },
        ],
      },
      options: [
        {
          value: "0",
          label: "全部",
        },
        {
          value: "1",
          label: "饮片",
        },
        {
          value: "2",
          label: "成药",
        },
        {
          value: "3",
          label: "输液",
        },
      ],
      num: "",
      patientTotal: 0,
      activeName: "third",
      pageIndex: 1,
      MyCommonPrescriptionList: [],
      MyCommonindex: -1,
      EditDrugCompositio: [],
      remarkOption: ["口服", "外用", "滴眼", "静脉注射", "静脉滴注", "肌内注射", "皮下注射", "皮内注射", "腔内注射", "椎管注射", "动脉注射", "心内注射", "球内注射", "皮下埋植", "口腔喷雾", "口腔吸入", "口腔黏膜给药", "滴鼻", "鼻腔喷雾", "鼻饲", "鼻腔吸入", "滴耳", "眼科外用", "涂于眼睑内", "含漱", "含服", "舌下含服", "直肠给药", "直肠塞入", "肛门涂抹", "阴道给药", "阴道塞入", "阴道擦洗"],
      frequencyOption: ["1日1次(qd)", "1日2次(bid)", "1日3次(tid)", "隔日1次(qod)", "必要时(prn)", "1日4次(qid)", "1周1次(qwd)", "隔周1次(qow)", "隔天1次(qod)", "每晚1次(qn)", "立即(st)"],
      usageOption: ["先煎", "后下", "包煎", "另煎", "烊化", "冲服", "捣碎"],
      kgOption: ["片", "粒", "支", "袋", "枚", "瓶", "滴", "适量", "ug", "mg", "g", "ul", "ml", "IU"],
      SpeeOption: ["g/分钟", "ml/分钟", "ml/小时", "分钟/次", "ug/分钟", "滴/分钟"],
      usage: ["口服", "外用"],
      requestOption: ["水煎服；分早晚两次空腹温服", "水煎服；分早晚两次饭前温服", "水煎服；分早晚两次饭后温服", "水煎服；分早晚凉服", "水煎服；1日3次空腹温服", "水煎服；1日3次饭前温服", "水煎服；1日3次饭后温服"],
      pageIndex: 1,
      dataTotal: 1,
      pageSize: 0,
      waitDataTotal:0
    };
  },
  mounted() {
    this.GetMyCommonPrescriptionData();
    this.getDrugCompositio();
     this.gettWaitData()
  },
  methods: {
     gettWaitData() {
      var _this = this;
      _this.waitDataTotal = 0;
      _this.drugpatientDomain.getMZIndex(
        function (data) {
          _this.waitDataTotal = data.data.waitAuditCount;
        },
        function (error) {
          _this.waitDataTotal = 0;
        }
      );
    },
    changePage(pageIndex) {
      this.pageIndex = pageIndex;
      this.GetMyCommonPrescriptionData();
    },
    Search() {
      this.pageIndex = 1;
      this.GetMyCommonPrescriptionData();
    },
    goEditClick(item) {
      var _this = this;
      if (item.type == 0) {
        _this.drugpatientDomain.getPrecriptionDetail(
          item.id,
          function (data) {
            _this.patientFormPre.id = data.data.id;
            _this.patientFormPre.usage = data.data.usage;
            _this.patientFormPre.prescriptionName = data.data.prescriptionName;
            _this.patientFormPre.dosageNumber = data.data.dosageNumber;
            _this.patientFormPre.request = data.data.request;
            _this.patientFormPre.dosage = data.data.dosage;
            _this.patientFormPre.frequency = data.data.frequency;
            _this.patientFormPre.type = 0;
            _this.patientFormPre.prescriptionType = 1;
            _this.patientFormPre.remark = data.data.remark;
            for (let j = 0; j < data.data.mzPrescriptionDetail.length; j++) {
              _this.patientFormPre.mzPrescriptionDetail.push({
                drugOrgId: data.data.mzPrescriptionDetail[j].drugOrgId,
                drugOrgName: data.data.mzPrescriptionDetail[j].drugOrgName,
                dosage: data.data.mzPrescriptionDetail[j].dosage,
                dosageUnit: data.data.mzPrescriptionDetail[j].dosageUnit,
                usage: data.data.mzPrescriptionDetail[j].usage,
                weight: data.data.mzPrescriptionDetail[j].weight,
                remark: data.data.mzPrescriptionDetail[j].remark,
                decoct:data.data.mzPrescriptionDetail[j].decoct,
                recommendDosage :data.data.mzPrescriptionDetail[j].recommendDosage,
              });
            }

            _this.addtoNew = 1;
          },
          function (error) {}
        );
      } else if (item.type == 1) {
        _this.drugpatientDomain.getPrecriptionDetail(item.id, function (data) {
          _this.patientFormPreCY.id = data.data.id;
          _this.patientFormPreCY.prescriptionName = data.data.prescriptionName;
          _this.patientFormPreCY.type = 1;
          _this.patientFormPreCY.prescriptionType = 1;
          for (let j = 0; j < data.data.mzPrescriptionDetail.length; j++) {
            _this.patientFormPreCY.mzPrescriptionDetail.push({
              drugOrgId: data.data.mzPrescriptionDetail[j].drugOrgId,
              drugOrgName: data.data.mzPrescriptionDetail[j].drugOrgName,
              dosageList: {
                dosage: data.data.mzPrescriptionDetail[j].dosage,
                dosageUnit: data.data.mzPrescriptionDetail[j].dosageUnit,
              },
              defaultDosageList: {
                defaultDosage: data.data.mzPrescriptionDetail[j].defaultDosage,
                defaultDosageUnit: data.data.mzPrescriptionDetail[j].defaultDosageUnit,
              },
              groupNumber: data.data.mzPrescriptionDetail[j].groupNumber,
              frequency: data.data.mzPrescriptionDetail[j].frequency,
              usage: data.data.mzPrescriptionDetail[j].usage,
            });
          }

          _this.addtoNew = 2;
        });
      } else {
        _this.drugpatientDomain.getPrecriptionDetail(item.id, function (data) {
          _this.patientFormPreSY.id = data.data.id;
          _this.patientFormPreSY.prescriptionName = data.data.prescriptionName;
          _this.patientFormPreSY.type = 2;
          _this.patientFormPreSY.prescriptionType = 1;
          for (let j = 0; j < data.data.mzPrescriptionGroup.length; j++) {
            // _this.patientFormPreSY.zucompositionList.push({
            //   mzPrescriptionTitle: [],
            //   mzPrescriptionDetail:[]
            // })
            for (let m = 0; m < data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY.length; m++) {
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionTitle[0].usage = data.data.mzPrescriptionGroup[j].usage;
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionTitle[0].frequency = data.data.mzPrescriptionGroup[j].frequency;
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionTitle[0].days = data.data.mzPrescriptionGroup[j].days;
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionTitle[0].startTime = data.data.mzPrescriptionGroup[j].startTime;
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed = data.data.mzPrescriptionGroup[j].drippingSpeed;
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit = data.data.mzPrescriptionGroup[j].drippingSpeedUnit;
              _this.patientFormPreSY.zucompositionList[j].mzPrescriptionDetail.push({
                drugOrgId: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].drugOrgId,
                drugOrgName: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].drugOrgName,
                days: data.data.mzPrescriptionGroup[j].days,
                st: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].st,
                dosageList: {
                  dosage: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].dosage,
                  dosageUnit: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].dosageUnit,
                },
                defaultDosageList: {
                  defaultDosage: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].defaultDosage,
                  defaultDosageUnit: data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].defaultDosageUnit,
                },
              });

              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionTitle[0].usage = data.data.mzPrescriptionGroup[j].usage;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionTitle[0].frequency = data.data.mzPrescriptionGroup[j].frequency;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionTitle[0].days = data.data.mzPrescriptionGroup[j].days;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionTitle[0].startTime = data.data.mzPrescriptionGroup[j].startTime;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed = data.data.mzPrescriptionGroup[j].drippingSpeed;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit = data.data.mzPrescriptionGroup[j].drippingSpeedUnit;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].drugOrgId = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].drugOrgId ? data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].drugOrgId : 0;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].drugOrgName = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].drugOrgName;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].st = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].st;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].dosageList.dosage = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].dosage;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].dosageList.dosageUnit = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].dosageUnit;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].defaultDosageList.defaultDosage = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].defaultDosage;
              // _this.patientFormPreSY.zucompositionList[i].mzPrescriptionDetail[n].defaultDosageList.defaultDosageUnit = data.data.mzPrescriptionGroup[j].mzPrescriptionDetailSY[m].defaultDosageUnit;
            }
          }
          _this.addtoNew = 3;
        });
      }
    },
    goaddSYzuDrugList(indexComSY1) {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        st: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
      };
      this.patientFormPreSY.zucompositionList[indexComSY1].mzPrescriptionDetail.push(row);
    },
    godeletSYzuDrugList(index2, index) {
      this.patientFormPreSY.zucompositionList[index2].mzPrescriptionDetail.splice(index, 1);
    },
    goaddSYzuList() {
      let row = {
        prescriptionName: "组",
        mzPrescriptionTitle: [
          {
            usage: "",
            frequency: "",
            drippingSpeedList: {
              drippingSpeed: "",
              drippingSpeedUnit: "",
            },

            startTime: "",
            days: "",
          },
        ],
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            st: "",
            dosageList: {
              dosage: "",
              dosageUnit: "",
            },
            defaultDosageList: {
              defaultDosage: "",
              defaultDosageUnit: "",
            },
          },
        ],
      };
      this.patientFormPreSY.zucompositionList.push(row);
    },
    godeleteSYzuList(index) {
      this.patientFormPreSY.zucompositionList.splice(index, 1);
    },
    inputChange() {
      this.GetHerbalPrescriptionListdata();
    },
    goherbalDetail(item) {
      var _this = this;
      _this.loading = true;
      _this.drugpatientDomain.getHerbalPrescriptionDetail(
        item.hashKey,
        function (data) {
          _this.herbalDetail = data.data;
          _this.herbalDetail.compositions.map(item => {
            return (item.compositionName1 = item.compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1"));
          });
          _this.loading = false;
        },
        function (error) {
          _this.herbalDetail = {};
        }
      );
    },
    addgetLoadPrescription() {
      var _this = this;
      // if (_this.herbalDetail.compositions) {
      //   let params = [];
      //   for (let i = 0; i < _this.herbalDetail.compositions.length; i++) {
      //     params.push({
      //       drugName: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1"),
      //       usage: _this.herbalDetail.compositions[i].remark,
      //       weight: _this.herbalDetail.compositions[i].dosage,
      //       drugHashKey: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$3"),
      //     });
      //   }
      //   _this.drugpatientDomain.getLoadPrescription(
      //     params,
      //     function (data) {
      //       _this.isdrawer = false;
      //       for (let i = 0; i < data.data.length; i++) {
      //         if (data.data[i].drugOrgId > 0) {
      //           _this.jdcompositionList.push(data.data[i]);
      //         }
      //       }
      //       if (_this.jdcompositionList.length > 0) {
      //         _this.patientFormPre.mzPrescriptionDetail.push(_this.jdcompositionList);
      //       } else {
      //         _this.$message({
      //           message: "无匹配药品",
      //           type: "warning",
      //         });
      //       }
      //     },
      //     function (error) {
      //       _this.jdcompositionList = [];
      //     }
      //   );
      // }
      if (_this.herbalDetail.compositions) {
        let params = [];
        for (let i = 0; i < _this.herbalDetail.compositions.length; i++) {
          let content = _this.herbalDetail.compositions[i].compositionName;
          let reg = /\[\[(.*?)\]\]/g;
          let result=[];
          // let itemArr = []
          // while ((itemArr = reg.exec(content)) !== null)
          // {
          //   var itemResult = itemArr[0]; 	//[[麸炒|Dict|48111]]
          //   result.push(itemResult);
          // }
          while(true){

            let itemArr = reg.exec(content);
            if(itemArr===null){
              break;
           }
           
            result.push(itemArr[0]);//[[麸炒|Dict|48111]]
          }
          let longestString = ''; // 存放最长字符串的变量
          if (result.length > 1) {

            result.map((item)=>{
              if (item.length > longestString.length) {
                item = longestString
              }
            })
          } else {
            longestString = result[0] // 存放最长字符串的变量
          }
          params.push({
            drugName: _this.herbalDetail.compositions[i].compositionName.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$1"),
            remark: _this.herbalDetail.compositions[i].remark,
            weight: _this.herbalDetail.compositions[i].dosage ? parseFloat(_this.herbalDetail.compositions[i].dosage) : 0,
            drugHashKey: longestString.replace(/\[\[(.+?)\|(.+?)\|(.+?)\]\]/g, "$3"),
            preHashKey: _this.herbalDetail.hashKey,
            recommendDosage:  _this.herbalDetail.compositions[i].recommendDosage
          });
          params.map((item, index) => {
            if (item.drugName == item.drugHashKey || !item.drugHashKey || item.drugHashKey == "Search") {
              item.drugHashKey = "";
            } else {
              item.drugHashKey = item.drugHashKey;
            }
          });
        }
        _this.drugpatientDomain.getLoadPrescription(
          params,
          function (data) {
            _this.isdrawer = false;
            _this.patientFormPre.mzPrescriptionDetail = [];
            if (_this.herbalDetail.herbalPrescriptionName) {
              _this.patientFormPre.prescriptionName += _this.herbalDetail.herbalPrescriptionName + ',';
            }
            let result = []
            data.data.map((item)=>{
              result.push({
                decoct:item.decoct,
                dosage:item.dosage,
                dosageUnit:item.dosageUnit,
                drugOrgId:item.drugOrgId,
                drugOrgName:item.drugOrgName,
                hashKey:item.hashKey,
                inventoryNumber:item.inventoryNumber,
                recommendDosage:item.recommendDosage,
                remark:item.remark,
                tradingPrice:item.tradingPrice,
                usage:item.usage,
                weight:item.weight
              })
            })
            _this.jdcompositionList = _this.jdcompositionList.concat(result);
            const uniqueArray = _this.jdcompositionList.filter((item, index, self) => self.findIndex(t => t.drugOrgName === item.drugOrgName && t.drugOrgName) === index);
            _this.patientFormPre.mzPrescriptionDetail = uniqueArray;
          },
          function (error) {
            _this.jdcompositionList = [];
          }
        );
      }
    },
    GetHerbalPrescriptionListdata() {
      var _this = this;
      _this.drugpatientDomain.GetHerbalPrescriptionList(
        "0",
        "1",
        _this.HerbalValue,
        function (data) {
          _this.HerbalList = data.data.results;
        },
        function (error) {
          _this.HerbalList = [];
        }
      );
    },
    goAdd() {
      this.isdrawer = true;
      this.GetHerbalPrescriptionListdata();
    },
    addpatientFormPreForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          let params = _this.patientFormPre;
          params.mzPrescriptionDetail.map((item,idnex)=>{
            item.drugOrgId = item.drugOrgId ? item.drugOrgId : null
          })
          _this.drugpatientDomain.getAddMZPrecriptionBatch(
            params,
            function (data) {
              _this.addtoNew = 0;
              _this.pageIndex = 1;
              _this.GetMyCommonPrescriptionData();
              _this.$message({
                type: "success",
                message: "已存为常用处方!",
              });
              console.log(data);
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    editpatientFormPreForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          let params = _this.patientFormPre;
          _this.drugpatientDomain.getEditMZPrecriptionBatch(
            params,
            function (data) {
              _this.addtoNew = 0;
              _this.pageIndex = 1;
              _this.GetMyCommonPrescriptionData();
              _this.$message({
                type: "success",
                message: "修改成功!",
              });
              console.log(data);
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    addCyFormPreForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          var _this = this;
          let params = _this.patientFormPreCY;
          for (let i = 0; i < params.mzPrescriptionDetail.length; i++) {
            params.mzPrescriptionDetail[i].defaultDosage = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosage;
            params.mzPrescriptionDetail[i].defaultDosageUnit = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosageUnit;
            params.mzPrescriptionDetail[i].dosage = params.mzPrescriptionDetail[i].dosageList.dosage;
            params.mzPrescriptionDetail[i].dosageUnit = params.mzPrescriptionDetail[i].dosageList.dosageUnit;
          }
          _this.drugpatientDomain.getAddMZPrecriptionBatch(
            params,
            function (data) {
              _this.addtoNew = 0;
              _this.pageIndex = 1;
              _this.GetMyCommonPrescriptionData();
              _this.$message({
                type: "success",
                message: "已存为常用处方!",
              });
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    editCyFormPreForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          var _this = this;
          let params = _this.patientFormPreCY;
          for (let i = 0; i < params.mzPrescriptionDetail.length; i++) {
            params.mzPrescriptionDetail[i].defaultDosage = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosage;
            params.mzPrescriptionDetail[i].defaultDosageUnit = params.mzPrescriptionDetail[i].defaultDosageList.defaultDosageUnit;
            params.mzPrescriptionDetail[i].dosage = params.mzPrescriptionDetail[i].dosageList.dosage;
            params.mzPrescriptionDetail[i].dosageUnit = params.mzPrescriptionDetail[i].dosageList.dosageUnit;
          }
          _this.drugpatientDomain.getEditMZPrecriptionBatch(
            params,
            function (data) {
              _this.addtoNew = 0;
              _this.pageIndex = 1;
              _this.GetMyCommonPrescriptionData();
              _this.$message({
                type: "success",
                message: "修改成功!",
              });
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    editSYFormPreForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          var _this = this;
          let params = _this.patientFormPreSY;
          params.mzPrescriptionDetail = [];
          params.mzPrescriptionGroup = params.zucompositionList;
          let params1 = {
            mzPrescriptionDetail: params.mzPrescriptionDetail,
            prescriptionName: params.prescriptionName,
            prescriptionType: params.prescriptionType,
            type: params.type,
            id: params.id,
            mzPrescriptionGroup: [],
          };
          for (let i = 0; i < params.zucompositionList.length; i++) {
            for (let j = 0; j < params.zucompositionList[i].mzPrescriptionDetail.length; j++) {
              params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosage = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage;
              params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit;
              params.zucompositionList[i].mzPrescriptionDetail[j].dosage = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosage;
              params.zucompositionList[i].mzPrescriptionDetail[j].dosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosageUnit;
            }
            params1.mzPrescriptionGroup.push({
              usage: params.zucompositionList[i].mzPrescriptionTitle[0].usage,
              days: params.zucompositionList[i].mzPrescriptionTitle[0].days,
              drippingSpeed: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
              drippingSpeedUnit: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
              frequency: params.zucompositionList[i].mzPrescriptionTitle[0].frequency,
              startTime: params.zucompositionList[i].mzPrescriptionTitle[0].startTime,
              mzPrescriptionDetailSY: params.zucompositionList[i].mzPrescriptionDetail,
            });
          }
          _this.drugpatientDomain.getEditMZPrecriptionBatch(
            params1,
            function (data) {
              _this.addtoNew = 0;
              _this.pageIndex = 1;
              _this.GetMyCommonPrescriptionData();
              _this.$message({
                type: "success",
                message: "修改成功!",
              });
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    addSYFormPreForm(formName) {
      var _this = this;
      _this.$refs[formName].validate(valid => {
        if (valid) {
          var _this = this;
          let params = _this.patientFormPreSY;
          params.mzPrescriptionDetail = [];
          params.mzPrescriptionGroup = params.zucompositionList;
          let params1 = {
            mzPrescriptionDetail: params.mzPrescriptionDetail,
            prescriptionName: params.prescriptionName,
            prescriptionType: params.prescriptionType,
            type: params.type,
            mzPrescriptionGroup: [],
          };
          for (let i = 0; i < params.zucompositionList.length; i++) {
            for (let j = 0; j < params.zucompositionList[i].mzPrescriptionDetail.length; j++) {
              params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosage = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosage;
              params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].defaultDosageList.defaultDosageUnit;
              params.zucompositionList[i].mzPrescriptionDetail[j].dosage = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosage;
              params.zucompositionList[i].mzPrescriptionDetail[j].dosageUnit = params.zucompositionList[i].mzPrescriptionDetail[j].dosageList.dosageUnit;
            }
            params1.mzPrescriptionGroup.push({
              usage: params.zucompositionList[i].mzPrescriptionTitle[0].usage,
              days: params.zucompositionList[i].mzPrescriptionTitle[0].days,
              drippingSpeed: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeed,
              drippingSpeedUnit: params.zucompositionList[i].mzPrescriptionTitle[0].drippingSpeedList.drippingSpeedUnit,
              frequency: params.zucompositionList[i].mzPrescriptionTitle[0].frequency,
              startTime: params.zucompositionList[i].mzPrescriptionTitle[0].startTime,
              mzPrescriptionDetailSY: params.zucompositionList[i].mzPrescriptionDetail,
            });
          }
          _this.drugpatientDomain.getAddMZPrecriptionBatch(
            params1,
            function (data) {
              _this.addtoNew = 0;
              _this.pageIndex = 1;
              _this.GetMyCommonPrescriptionData();
              _this.$message({
                type: "success",
                message: "已存为常用处方!",
              });
            },
            function (error) {
              console.log(error);
            }
          );
        }
      });
    },
    gonaddPre() {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosage: "",
        dosageUnit: "g",
        usage: "",
        weight: "",
        remark: "",
        decoct:"",
        recommendDosage :"",
      };
      this.patientFormPre.mzPrescriptionDetail.push(row);
    },
    gondeletePre(index) {
      this.patientFormPre.mzPrescriptionDetail.splice(index, 1);
    },
    gonaddPreCY() {
      let row = {
        drugOrgId: "",
        drugOrgName: "",
        dosageList: {
          dosage: "",
          dosageUnit: "",
        },
        defaultDosageList: {
          defaultDosage: "",
          defaultDosageUnit: "",
        },
        groupNumber: "",
        frequency: "",
        usage: "",
        remark: "",
      };
      this.patientFormPreCY.mzPrescriptionDetail.push(row);
    },
    gondeletePreCY(index) {
      this.patientFormPreCY.mzPrescriptionDetail.splice(index, 1);
    },
    goAddcompositionList() {
      let row = {
        mzPrescriptionDetail: [
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct:"",
            recommendDosage :"",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct:"",
            recommendDosage :"",
          },
          {
            drugOrgId: "",
            drugOrgName: "",
            dosage: "",
            dosageUnit: "g",
            usage: "",
            weight: "",
            remark: "",
            decoct:"",
            recommendDosage :"",
          },
        ],
        usage: "",
        remark: "",
        dosage: "",
        dosageNumber: "",
        frequency: "",
        prescriptionName: "",
        request: "",
        prescriptionType: 1,
        // patientDiagnoseId:''
      };
      this.patientFormPre.compositionList.push(row);
    },
    godeleteCompositionList(index) {
      this.patientFormPre.compositionList.splice(index, 1);
    },
    getDrugCompositio() {
      var _this = this;
      _this.$nextTick(()=>{
        _this.drugpatientDomain.GetDrugByOrgInTop10(
        "%20",
        function (data) {
          for (let i = 0; i < data.data.length; i++) {
            var label = `${data.data[i].mzDrug.drugName}`;
            _this.EditDrugCompositio.push({
              drugOrgId: data.data[i].id,
              drugOrgName: label,
              drugOrgName1: data.data[i].mzDrug.drugName,
              drug: data.data[i],
            });
          }
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      );
          })
   
    },
    ConstituteChange(val, item) {
      this.EditDrugCompositio.map(res => {
        if (res.drugOrgId == val) {
          console.log(res);
          item.drugOrgId = res.drugOrgId;
          item.drugOrgName = res.drugOrgName1;
          item.inventoryNumber = res.drug.inventoryNumber;
          item.tradingPrice = res.drug.tradingPrice;
          item.dosage = res.drug.dosage ? res.drug.dosage : "";
          item.usage = res.drug.usage ? res.drug.usage : "";
          item.remark = res.drug.remark ? res.drug.remark : "";
          item.hashKey = res.drug.mzDrug.hashKey ? res.drug.mzDrug.hashKey : "";
          item.defaultDosageList.defaultDosage = res.drug.isSeparate ? res.drug.separateNumber : res.drug.mzDrug.defaultDosage;
          item.defaultDosageList.defaultDosageUnit = res.drug.isSeparate ? res.drug.separateNumberUnit : res.drug.mzDrug.defaultDosageUnit;
          item.dosageList.dosageUnit = res.drug.mzDrug.dosageUnit;
          item.dosageList.dosage = res.drug.mzDrug.dosage;
          item.frequency = res.drug.mzDrug.frequency;
        }
      });
    },
    SelectComposition(val) {
      var _this = this;
      _this.drugpatientDomain.GetDrugByOrgInTop10(
        val,
        function (data) {
          _this.EditDrugCompositio = data.data.map(function (item) {
            // var label = `[${item.categoryName}]${item.compositionName}`;
            var label = `${item.mzDrug.drugName}（${item.mzDrug.specification}/${item.mzDrug.specificationUnit}-${item.mzDrug.productFactory}）`;
            // var label = `${item.mzDrug.drugName}`;
            // if (item.compositionAlias) label += `${item.compositionAlias}`;
            return {
              drugOrgName1: item.mzDrug.drugName,
              drugOrgName: label,
              drugOrgId: item.id,
              drug: item,
              inventoryNumber: item.inventoryNumber,
            };
          });
        },
        function (err) {
          _this.EditDrugCompositio = [];
          console.log(err);
        }
      )
    },
    deleteClick(item) {
      var _this = this;
      _this
        .$confirm("是否删除此处方?", "提示", {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
          type: "warning",
        })
        .then(() => {
          _this.drugpatientDomain.GetdeleteMyCommonPrescription(
            item.id,
            function (data) {
              _this.$message({
                type: "success",
                message: "删除成功!",
              });
              _this.GetMyCommonPrescriptionData();
            },
            function (error) {
              console.log(error);
            }
          );
        })
        .catch(() => {
          _this.$message({
            type: "info",
            message: "已取消删除",
          });
        });
    },
    GetMyCommonPrescriptionData() {
      var _this = this;
      _this.MyCommonPrescriptionList;
      _this.drugpatientDomain.GetMyCommonPrescription(
        _this.searchForm.type,
        _this.searchForm.keyword,
        _this.pageIndex,
        15,
        function (data) {
          _this.MyCommonindex = -1;
          _this.MyCommonPrescriptionList = data.data.results;
          _this.pageIndex = data.data.pageIndex;
          _this.pageSize = data.data.pageSize;
          _this.dataTotal = data.data.dataTotal;
          
        },
        function (error) {
          console.log(error);
        }
      );
    },
    handleCurrentChange(pageNum) {
      this.patientcurrentPage = pageNum;
      this.pageIndex = pageNum;
      this.GetMyCommonPrescriptionData();
    },
    GetorgMyCommonPrescriptionData() {
      let type = -1;
      var _this = this;
      let keyword = _this.MyCommonKeyword ? _this.MyCommonKeyword : "%20";
      _this.drugpatientDomain.GetPrecriptionListOrg(
        type,
        keyword,
        _this.pageIndex,
        function (data) {
          _this.MyCommonPrescriptionList = data.data.results;
          _this.MyCommonindex = -1;
        },
        function (error) {
          console.log(error);
        }
      );
    },
    // goDetail(){
    //     this.$router.push({
    //         name:'intPatientListDtail'
    //     })
    // },
    goDoctor() {
      if (this.userInfo.isAuditDiagnose == "True") {
        this.$router.push({
          name: "PremangePatientList",
        });
      } else {
        this.$message({
          message: "暂无审方权限,请联系管理员添加",
          type: "warning",
        });
      }
    },
    goBack() {
      this.addtoNew = 0;
      this.patientFormPre = {
        mzPrescriptionDetail: [],
        usage: "",
        remark: "",
        dosage: "",
        dosageNumber: "",
        frequency: "",
        prescriptionName: "",
        request: "",
        type: 0,
        prescriptionType: 1,
      }
    },
    gonewDoctor() {
      this.addtoNew = 1;
    },
    gonmedDoctor() {
      this.addtoNew = 2;
    },
    gotraDoctor() {
      this.addtoNew = 3;
    },
  },
};
</script>
<style scoped>
::v-deep .el-form-item {
  margin-bottom: 0;
}
.pageBox {
  text-align: center;
  margin-top: 10px;
}
.firstRight3Box {
  margin-top: 10px;
  border-bottom: 1px solid #e1e3e5;
  max-height: 300px;
  overflow-y: auto;
}
.firstRight3BottomBoxDiv {
  font-size: 18px;
  color: #00afb5;
}
.inteBox {
  margin: 0;
  padding: 0;
  background-color: #f0f2f5;
}
.titleTop {
  padding: 0px 20px;
  background-color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.titleTopLeft {
  width: 85%;
  display: flex;
  align-items: center;
}
.titleTopLeftImg {
  width: 123px;
  height: 24px;
}
.titleTopLeftImg img {
  width: 100%;
  height: 100%;
}
.tabBox {
  padding-left: 12%;
  margin-top: 10px;
}
.el-tabs__item {
  height: 60px;
  color: white !important;
  text-align: center;
  padding-top: 10px;
  font-size: 25px !important;
  background-color: #00afb5 !important;
  border: none !important;
}
.is-active {
  color: white !important;
  background-color: #00afb5 !important;
}
::v-deep .el-tabs__nav-wrap::after {
  background-color: #ffffff !important;
}
.titleTopRight {
  width: 10%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.titleTopRight div {
  margin-left: 10px;
}
.titleTopLine {
  width: 100%;
  height: 12px;
}
.titleTopCenter {
  width: 100%;
  /* height: 100; */
  display: flex;
}
.titleTopCenterLeft {
  width: 15%;
  background-color: #ffffff;
  /* padding-top: 30px; */
  /* max-height: 750px; */
  overflow: auto;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.titleTopCenterLeftBox {
  font-size: 18px;
  color: #06add7;
  border: 1px solid #06afd3;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleTopCenterCenter {
  width: 80%;
  border-radius: 6px;
  height: calc(100vh - 64px - 24px);
  overflow: auto;
}
.titleTopCenterCenterTop {
  background-color: #ffffff;
  padding: 10px 40px;
}
.flex-l {
  display: flex;
  align-items: center;
}
.flex-l-s {
  display: flex;
  align-items: center;
  justify-content: space-around;
}
.noflex-l-s {
  display: flex;
  justify-content: space-around;
}
.flex-l-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.Content1Title {
  padding: 20px 40px;
  padding-left: 0;
  font-size: 18px;
  font-weight: bold;
  color: #333333;
  padding-bottom: 0;
  text-align: left;
}
.titleTopLeftTableC {
  color: #06add7;
  cursor: pointer;
}
.ageInput {
  width: 80%;
}
.typeChecked {
  font-size: 14px;
  color: #ffffff;
  padding: 0px 15px;
  text-align: center;
  background: #00afb5;
  border-radius: 6px;
  cursor: pointer;
}
.notypeChecked {
  background: #f3f9f9;
  border: 1px solid #b9dcdd;
  padding: 0px 15px;
  border-radius: 6px;
  color: #00afb5;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
}
.table3Box {
  border: 1px solid #b9dcdd;
  border-radius: 6px;
  padding: 30px;
  margin-top: 10px;
}
.titleTopright1Line {
  border-bottom: 1px dashed #999999;
}
.titleTopright1 {
  font-size: 16px;
  color: #06add7;
}
.stepInput {
  width: 60%;
  margin-right: 10px;
}
.titleTopCenterLeftBoxno {
  font-size: 18px;
  color: #cccccc;
  border: 1px solid #cccccc;
  border-radius: 6px;
  width: 150px;
  height: 40px;
  text-align: center;
  line-height: 40px;
  margin: 30px auto;
  cursor: pointer;
  /* margin-top: 0; */
}
.titleSize16 {
  font-size: 16px;
}
.titleSize14 {
  font-size: 14px;
}
::v-deep .el-pagination {
  padding: 10px 5px;
}
.el-page-header {
  line-height: 73px;
  /* padding-left: 20px; */
}
.infoWarn1 {
  position: absolute;
  bottom: 0px;
  right: 5px;
}
</style>
